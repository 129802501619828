import { Box, Center, Loader } from '@mantine/core';
import { IconQuote } from '@tabler/icons-react';
import React, { useState } from 'react';
import QuotesTable from './QuotesTable';
import QuotesGrid from './QuotesGrid';
import { useGetQuotes } from '../queries/quotes/useGetQuotes';
import { sourceLanguage, targetLangs } from '../utils/content/contentUtils';
import { IQuote, useQuotesStore } from '../store/quote/useQuotesStore';
import { AddLanguageModal } from '../components/AddLanguageModal';
import { IDeeplTranslationBody, useGetDeeplTranslation } from '../queries/content/useGetDeeplTranslation';
import { AddEditQuoteModal } from './AddEditQuoteModal';
import { useDeleteQuote } from '../queries/quotes/useDeleteQuote';
import FileExplorer, { FileExplorerIdentifier } from '../file-explorer/FileExplorer';
import {
  FileExplorerStatus,
  FileExplorerViewType,
  useFileExplorerStore,
} from '../store/file-explorer/useFileExplorerStore';

interface Props {}

const QuotesPage: React.FC<Props> = () => {
  // File Explorer Store
  const fileExplorerViewType = useFileExplorerStore((s) => s.fileExplorerViewType);

  //States
  const [isCreateEditQuoteModalOpen, setIsCreateEditQuoteModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<{ label: string; value: string }>(sourceLanguage);
  const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = useState(false);
  const [editQuoteId, setEditQuoteId] = useState<string | undefined>(undefined);

  const quotes = useQuotesStore((s) => s.quotes);
  const setQuotes = useQuotesStore((s) => s.handleSetValues);
  const resetQuotesStore = useQuotesStore((s) => s.resetStore);

  const { data: quotesData, isLoading } = useGetQuotes();

  const handleChangeTab = (newValue: any): void => {
    const selected = targetLangs.find((x) => x.value === newValue);
    setSelectedLanguage(selected as { label: string; value: string });
  };

  const { mutateAsync: translateFromDeepl, isLoading: isTranslationLoading } = useGetDeeplTranslation({
    onSettled: () => {
      setIsAddLanguageModalOpen(false);
    },
  });

  const handleAddLanguage = async (): Promise<void> => {
    const bodyForDeepl: IDeeplTranslationBody = {
      text: [`${quotes.find((x) => x?.language?.value === sourceLanguage.value)?.quoteText}`],
      targetLanguage: selectedLanguage.value,
      sourceLanguage: sourceLanguage.value,
      options: {
        tagHandling: 'html',
      },
    };

    const translatedContent: any = await translateFromDeepl({ body: bodyForDeepl });

    const translatedQuoteText = translatedContent?.[0]?.text ?? '';

    const allQuotes = [...quotes];

    const newLang: IQuote = {
      id: undefined,
      language: selectedLanguage,
      isSource: false,
      quoteText: translatedQuoteText,
      status: FileExplorerStatus.DRAFT,
    };

    allQuotes.push(newLang);

    setQuotes(allQuotes);
  };

  const handleCreateNewQuote = (): void => {
    setEditQuoteId(undefined);
    resetQuotesStore();
    setIsCreateEditQuoteModalOpen(true);
  };

  const handleEditQuote = (quoteId: string): void => {
    setEditQuoteId(quoteId);
    setIsCreateEditQuoteModalOpen(true);
  };

  const { mutateAsync: delQuote } = useDeleteQuote({
    onSettled: () => {
      window.location.reload();
    },
  });

  const handleDeleteQuote = async (quoteId: string): Promise<void> => {
    if (window.confirm('Are you sure to delete quote?')) {
      await delQuote({ id: quoteId });
    }
  };

  return (
    <FileExplorer
      identifier={FileExplorerIdentifier.QUOTE_CATEGORY}
      icon={<IconQuote size={24} />}
      title="Quotes"
      selectedItems={[]}
      itemsData={quotesData}
      handleCreateNew={handleCreateNewQuote}
    >
      {isLoading ? (
        <Center mih={300}>
          <Loader />
        </Center>
      ) : (
        <Box>
          {fileExplorerViewType === FileExplorerViewType.LIST ? (
            <QuotesTable quotesData={quotesData} />
          ) : (
            <QuotesGrid
              quotesData={quotesData}
              onClick={(id: string) => handleEditQuote(id)}
              onDelete={(id: string) => handleDeleteQuote(id)}
            />
          )}
        </Box>
      )}
      {/* ADD NEW QUOTE - MODAL */}
      <AddEditQuoteModal
        type={editQuoteId ? 'update' : 'create'}
        editQuoteId={editQuoteId}
        isOpen={isCreateEditQuoteModalOpen}
        onClose={() => setIsCreateEditQuoteModalOpen(false)}
        selectedLanguage={selectedLanguage}
        handleChangeTab={handleChangeTab}
        handleOpenAddLanguageModal={() => setIsAddLanguageModalOpen(true)}
      />

      {/* ADD NEW LANGUAGE TO QUOTE - MODAL */}
      <AddLanguageModal
        isOpen={isAddLanguageModalOpen}
        onClose={() => setIsAddLanguageModalOpen(false)}
        selectedLanguage={selectedLanguage}
        isTranslationLoading={isTranslationLoading}
        maxCharacterLimit={100}
        handleAddLanguage={handleAddLanguage}
        setSelectedLanguage={(val: { value: string; label: string }) => setSelectedLanguage(val)}
        hasCharacterLimitError={false}
      />
    </FileExplorer>
  );

  // return (
  //   <Card>
  //     <Grid>
  //       <Grid.Col span={12}>
  //         <Center inline p={4} style={{ width: '100%', justifyContent: 'space-between' }}>
  //           <Center inline>
  //             <IconFolder size={24} />
  //             <Text size="xl" style={{ marginLeft: 8, fontWeight: 'bold' }}>
  //               Quotes
  //             </Text>
  //           </Center>
  //           <Center inline>
  //             <Button onClick={handleCreateNewQuote} variant="filled" leftSection={<IconPlus size={18} />}>
  //               Create new
  //             </Button>
  //           </Center>
  //         </Center>
  //       </Grid.Col>
  //       <Grid.Col span={12}>
  //         <Center
  //           inline
  //           px={12}
  //           py={10}
  //           bg={'#F1F3F9'}
  //           style={{ width: '100%', justifyContent: 'space-between', borderRadius: 5 }}
  //         >
  //           <Center inline>
  //             <TextInput
  //               leftSection={<IconSearch size={16} />}
  //               size="sm"
  //               style={{ minWidth: 300 }}
  //               mr={8}
  //               placeholder="Search..."
  //               value={searchText}
  //               onChange={(e) => setSearchText(e.target.value)}
  //             />
  //             <Select
  //               defaultValue="all"
  //               value={statusValue}
  //               onChange={(value) => setStatusValue(value as ContentStatusType)}
  //               size="sm"
  //               allowDeselect={false}
  //               comboboxProps={{ position: 'bottom' }}
  //               placeholder="Status"
  //               mr={8}
  //               data={statusArr}
  //               style={{ maxWidth: 120 }}
  //             />
  //             {selectedContents.length > 0 && (
  //               <Button color="red" variant="filled" size="sm" leftSection={<IconTrash size={18} />}>
  //                 Delete
  //               </Button>
  //             )}
  //           </Center>
  //           <Center inline>
  //             <Tooltip label={folderType === 'list' ? ' Grid  view' : 'List view'}>
  //               <ActionIcon color="inherit" onClick={toggleFolderType}>
  //                 {folderType === 'list' ? <IconApps /> : <IconList />}
  //               </ActionIcon>
  //             </Tooltip>
  //           </Center>
  //         </Center>
  //       </Grid.Col>
  //       <Grid.Col span={12}>
  //         <Center inline style={{ marginBottom: 12 }}>
  //           <Button
  //             onClick={() => setIsAddFolderModalOpen(true)}
  //             size="sm"
  //             variant="light"
  //             leftSection={<IconPlus size={18} />}
  //             mr={12}
  //           >
  //             Create new category
  //           </Button>
  //           {activeTab && (
  //             <Button
  //               onClick={() => setIsDeleteCategoryModalOpen(true)}
  //               leftSection={<IconTrash size={18} />}
  //               color="red"
  //               size="sm"
  //               variant="light"
  //             >
  //               Delete{' '}
  //               <Text px={4} td="underline" fw={'bold'}>
  //                 {categoriesData?.find((x: any) => x?.id === activeTab)?.title}
  //               </Text>{' '}
  //               category
  //             </Button>
  //           )}
  //         </Center>
  //         {categoriesData && activeTab && (
  //           <Tabs value={activeTab} onChange={setActiveTab} orientation="vertical">
  //             <Tabs.List>
  //               {categoriesData?.map((item: any) => {
  //                 return (
  //                   <Tabs.Tab
  //                     key={item?.id}
  //                     leftSection={
  //                       item?.id === activeTab ? <IconFolderOpen color={theme.colors.blue[6]} /> : <IconFolder />
  //                     }
  //                     value={item?.id ?? ''}
  //                   >
  //                     <Text color={activeTab === item?.id ? theme.colors.blue[6] : 'unset'}>{item?.title}</Text>
  //                   </Tabs.Tab>
  //                 );
  //               })}
  //             </Tabs.List>
  //             <Tabs.Panel key={activeTab} value={activeTab as string} p={12} style={{ width: '100%' }}>
  //               {folderType === 'list' ? (
  //                 <QuotesTable quotesData={quotesData} />
  //               ) : (
  //                 <QuotesGrid
  //                   quotesData={quotesData}
  //                   activeTab={activeTab}
  //                   onClick={(id: string) => handleEditQuote(id)}
  //                   onDelete={(id: string) => handleDeleteQuote(id)}
  //                 />
  //               )}
  //             </Tabs.Panel>
  //           </Tabs>
  //         )}
  //       </Grid.Col>
  //     </Grid>

  //     {/* ADD NEW CATEGORY - MODAL */}
  //     <Modal
  //       title="Create new category"
  //       opened={isAddFolderModalOpen}
  //       onClose={() => setIsAddFolderModalOpen(false)}
  //       closeOnClickOutside
  //       centered
  //       size="md"
  //     >
  //       <Flex direction="column">
  //         <Center my={18}>
  //           <TextInput
  //             size="md"
  //             style={{ width: '100%' }}
  //             label="Category name"
  //             value={newCategoryName}
  //             placeholder="Give a name to your category"
  //             onChange={(e) => setNewCategoryName(e.target.value)}
  //           />
  //         </Center>
  //         <Flex align="center" justify="flex-end" direction="row" mt={18}>
  //           <Button variant="outline" onClick={() => setIsAddFolderModalOpen(false)}>
  //             Cancel
  //           </Button>
  //           <Button
  //             loading={isCreateCategoryLoading}
  //             onClick={handleCreateCategory}
  //             disabled={!newCategoryName}
  //             variant="filled"
  //             bg={theme.primaryColor}
  //             ml={8}
  //           >
  //             Create
  //           </Button>
  //         </Flex>
  //       </Flex>
  //     </Modal>

  //     {/* DELETE CATEGORY MODAL */}
  //     <Modal
  //       title="Delete category"
  //       opened={isDeleteCategoryModalOpen}
  //       onClose={() => setIsDeleteCategoryModalOpen(false)}
  //       closeOnClickOutside
  //       centered
  //       size={quotesData?.length > 0 ? 'xl' : 'sm'}
  //     >
  //       <Divider />
  //       <Flex direction="column" my={12}>
  //         {quotesData?.length > 0 ? (
  //           <Flex direction="column">
  //             <Text>
  //               This category has quotes. Would you like to move all quotes or delete all quotes and the category?
  //             </Text>
  //             <Flex my={12} direction="row" style={{ width: '100%' }}>
  //               <Radio
  //                 label="Move quotes"
  //                 checked={checkedOptionCategoryDelete === 'move-quotes'}
  //                 onChange={() => setCheckedOptionCategoryDelete('move-quotes')}
  //                 style={{ marginRight: 12 }}
  //               />
  //               <Radio
  //                 label="Delete quotes"
  //                 checked={checkedOptionCategoryDelete === 'delete-quotes'}
  //                 onChange={() => setCheckedOptionCategoryDelete('delete-quotes')}
  //               />
  //             </Flex>
  //             {checkedOptionCategoryDelete === 'move-quotes' && (
  //               <Select
  //                 size="sm"
  //                 comboboxProps={{ position: 'bottom' }}
  //                 data={categoriesData?.map((x: any) => {
  //                   return {
  //                     label: x?.title,
  //                     value: x?.id,
  //                   };
  //                 })}
  //                 label="Move quotes to"
  //                 onChange={(val) => setSelectedCategoryToMoveQuotesInto(val)}
  //               />
  //             )}
  //             <Flex align="center" justify="flex-end" direction="row" mt={18}>
  //               <Button variant="outline" onClick={() => setIsDeleteCategoryModalOpen(false)}>
  //                 Cancel
  //               </Button>
  //               <Button
  //                 loading={isMoveOrDeleteLoading}
  //                 onClick={handleMoveOrDeleteContentsCategories}
  //                 variant="filled"
  //                 bg={theme.primaryColor}
  //                 ml={8}
  //               >
  //                 {checkedOptionCategoryDelete === 'move-quotes'
  //                   ? 'Move quotes & delete category'
  //                   : 'Delete category & quotes'}
  //               </Button>
  //             </Flex>
  //           </Flex>
  //         ) : (
  //           <Flex w={'100%'} direction="column">
  //             <Text>
  //               Are you sure to delete {categoriesData?.find((x: any) => x?.id === activeTab)?.title} {` `}
  //               category?
  //             </Text>
  //             <Flex align="center" justify="flex-end" direction="row" mt={18}>
  //               <Button variant="outline" onClick={() => setIsAddFolderModalOpen(false)}>
  //                 Cancel
  //               </Button>
  //               <Button variant="filled" bg={theme.primaryColor} ml={8}>
  //                 Delete category
  //               </Button>
  //             </Flex>
  //           </Flex>
  //         )}
  //       </Flex>
  //     </Modal>
  //   </Card>
  // );
};

export default QuotesPage;
