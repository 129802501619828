import React from 'react';
import { ContentSchema, ContentStatusType } from '../types/content/contentTypes';
import { ContentForm } from './ContentForm';
import { useMedplum } from '@medplum/react';
import { Binary } from '@medplum/fhirtypes';
import { useContentStore } from '../store/content/useContentStore';
import { useGetContentDetail } from '../queries/content/useGetContentDetail';
import { sourceLanguage, targetLangs } from '../utils/content/contentUtils';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { LoadingOverlay } from '@mantine/core';
import { useCreateUpdateContent } from '../queries/content/useCreateUpdateContent';
import { useGetTagsByIds } from '../queries/content/useGetTagsByIds';

interface Props {}

const EditContentPage: React.FC<Props> = () => {
  const medplum = useMedplum();
  const values = useContentStore((s) => s.values);
  const setValues = useContentStore((s) => s.handleSetValues);

  const { mutateAsync: updateContent, isLoading: isUpdateContentLoading } = useCreateUpdateContent(values, {
    onSettled: async () => {
      setSelectedLanguage(sourceLanguage);
    },
  });

  const { data: contentDetail, isLoading } = useGetContentDetail();

  const ids = React.useMemo(() => contentDetail?.[0]?.tags?.map((t: any) => t?.value) ?? [], [contentDetail]);

  const { data: tagsData } = useGetTagsByIds(ids, {
    enabled: !!contentDetail,
    onSuccess: (resp) => {
      setValues({
        ...values,
        tags: resp,
      });
    },
  });

  const parsedSection: any = React.useMemo(() => {
    return contentDetail
      ?.map((content: any) => {
        const lang = content?.language;
        const contentStatus = content?.status;

        let mergedString = '';
        const deleteCoverImage = content?.section?.filter((obj: any) => obj?.code?.id !== 'cover-image');

        deleteCoverImage?.forEach((obj: any) => {
          if (obj) {
            mergedString += obj?.text?.div;
          }
        });

        const contentState = stateFromHTML(mergedString);

        const languageLabel = targetLangs.find((x) => x.value === lang)?.label ?? lang;

        return {
          id: content?.id ?? '',
          isSource: lang === sourceLanguage.value,
          language: { label: languageLabel, value: lang ?? '' },
          languageLabel: languageLabel,
          title: content.title ?? '',
          description: content?.description ?? '',
          content: contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty(),
          status: contentStatus ?? ContentStatusType.DRAFT,
        };
      })
      .sort((a: any) => (a.isSource ? -1 : 1));
  }, [contentDetail]);

  const findSourceContent = React.useMemo(
    () => contentDetail?.find((content: any) => content?.language === sourceLanguage.value),
    [contentDetail]
  );

  React.useEffect(() => {
    if (!findSourceContent) {
      return;
    }
    if (!parsedSection) {
      return;
    }
    if (!tagsData) {
      return;
    }
    setValues({
      ...values,
      contentId: findSourceContent?.id ?? '',
      contents: parsedSection,
      categoryId: findSourceContent?.categoryId ?? '',
      returnedCoverImgUrl: findSourceContent?.coverImage ?? '',
      status: findSourceContent?.status ?? ContentStatusType.DRAFT,
      formType: 'update' as ContentSchema['formType'],
    });
  }, [findSourceContent, tagsData]);

  const [selectedLanguage, setSelectedLanguage] = React.useState<{ label: string; value: string }>(sourceLanguage);

  const handleUploadCoverImage = async (file: File): Promise<string | undefined> => {
    const binary: Binary = await medplum.createBinary(file, file.name, file.type);

    return binary.url as string;
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const coverImageUrl = values.coverImageFile
        ? await handleUploadCoverImage(values?.coverImageFile as File)
        : values.returnedCoverImgUrl;

      await updateContent({ coverImageUrl });
    } catch (error) {
      console.error({ error });
    }
  };

  if (isUpdateContentLoading || isLoading) {
    return <LoadingOverlay visible overlayProps={{ blur: 2 }} />;
  }

  return (
    <ContentForm
      onSubmit={handleSubmit}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
    />
  );
};

export default EditContentPage;
