import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { BundleEntry, Composition, List } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

interface IParams {
  categoryId: string | undefined;
  compositions: Composition[];
  categoryMoveId: string | null;
  type: 'move-contents' | 'delete-contents';
}

export const useMoveOrDeleteContentsCategory = (
  data: IParams,
  options?: Omit<UseMutationOptions<any, unknown, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<any> => {
  const medplum = useMedplum();

  return useMutation(async () => {
    const compositionEntries: BundleEntry<Composition>[] = data?.compositions?.map((comp: Composition) => {
      if (data.type === 'move-contents') {
        return {
          request: {
            method: 'PUT',
            url: `/Composition/${comp?.id}`,
          },
          resource: {
            ...comp,
            subject: {
              reference: `List/${data.categoryMoveId}`,
            },
          },
        };
      } else {
        return {
          request: {
            method: 'DELETE',
            url: `/Composition/${comp?.id}`,
          },
        };
      }
    });

    const bundleEntries: BundleEntry<Composition | List>[] = [
      ...compositionEntries,
      {
        request: {
          method: 'DELETE',
          url: '/List/' + data.categoryId, // Delete category here
        },
      },
    ];

    return medplum.executeBatch({
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    });
  }, options);
};
