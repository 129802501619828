import { ActionIcon, Box, Checkbox, Flex, Table, Text, Title, useMantineTheme } from '@mantine/core';
import React from 'react';
import { useContentStore } from '../store/content/useContentStore';
import moment from 'moment';
import { Composition } from '@medplum/fhirtypes';
import { targetLangs } from '../utils/content/contentUtils';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { useDeleteComposition } from '../queries/content/useDeleteComposition';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../queries/query-keys';

interface Props {
  contentsData: Composition[];
  onClick: (id: string) => void;
}

const ContentTable: React.FC<Props> = ({ contentsData, onClick }) => {
  const theme = useMantineTheme();
  const queryClient = useQueryClient();

  const { selectedContents, setSelectedContents } = useContentStore((x) => ({
    selectedContents: x.selectedContents,
    setSelectedContents: x.setSelectedContents,
  }));

  const { mutateAsync: delComposition } = useDeleteComposition({
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.GET_CONTENTS);
    },
  });

  const handleDeleteContent = async (id: string): Promise<void> => {
    if (confirm('Are you sure to delete this content?')) {
      await delComposition(id);
    }
  };

  const rows = contentsData?.map((c: Composition) => (
    <Table.Tr key={c?.id}>
      <Table.Td>
        <Checkbox
          aria-label="Select row"
          checked={selectedContents?.includes(c?.id as string)}
          onChange={(event) =>
            setSelectedContents(
              event.currentTarget.checked
                ? [...selectedContents, c?.id as string]
                : selectedContents.filter((position) => position !== (c?.id as string))
            )
          }
        />
      </Table.Td>
      <Table.Td>{c?.title}</Table.Td>
      <Table.Td>
        {c?.section?.[c?.section?.length - 1]?.code?.coding?.find((cod) => cod?.code === 'reading-time')?.display ??
          '--'}
      </Table.Td>
      <Table.Td style={{ textTransform: 'capitalize' }}>{c?.status}</Table.Td>
      <Table.Td>{targetLangs?.find((t) => t?.value === c?.language)?.label}</Table.Td>
      <Table.Td>{moment(c?.date).format('DD MMM YYYY, HH:mm')}</Table.Td>
      <Table.Td>
        <Flex direction="row" align="center" justify="flex-end">
          <ActionIcon onClick={() => onClick(c?.id as string)} size="md" variant="subtle" color={theme.colors.blue[6]}>
            <IconPencil size={20} />
          </ActionIcon>
          <ActionIcon
            onClick={() => handleDeleteContent(String(c?.id))}
            ml={12}
            size="md"
            variant="subtle"
            color={theme.colors.red[6]}
          >
            <IconTrash size={20} />
          </ActionIcon>
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Box px={12}>
      <Title order={3}>Contents</Title>
      <Text c={theme.colors.gray[6]} mb={24}>
        {contentsData?.length <= 0 ? 'No' : contentsData?.length} content(s) found.
      </Text>
      {contentsData?.length > 0 && (
        <Table withTableBorder striped highlightOnHover withColumnBorders withRowBorders verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th>Est. reading time</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Language</Table.Th>
              <Table.Th>Last modified</Table.Th>
              <Table.Th style={{ textAlign: 'end' }}>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
    </Box>
  );
};

export default ContentTable;
