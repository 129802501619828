/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';
import { FileExplorerStatus, useFileExplorerStore } from '../../store/file-explorer/useFileExplorerStore';

export const useGetQuotes = (options?: UseQueryOptions<any | undefined, Error>): UseQueryResult<any> => {
  const medplum = useMedplum();
  const fileExplorerSearchText = useFileExplorerStore((s) => s.fileExplorerSearchText);
  const fileExplorerLanguage = useFileExplorerStore((s) => s.fileExplorerLanguage);
  const fileExplorerStatus = useFileExplorerStore((s) => s.fileExplorerStatus);
  const fileExplorerSelectedCategoryId = useFileExplorerStore((s) => s.fileExplorerSelectedCategoryId);

  return useQuery<any | undefined, Error>(
    [
      QueryKeys.GET_QUOTES,
      fileExplorerSelectedCategoryId,
      fileExplorerLanguage,
      fileExplorerStatus,
      fileExplorerSearchText,
    ],
    async () =>
      medplum
        .search(
          'Communication',
          {
            'based-on': `List/${fileExplorerSelectedCategoryId}`,
            identifier: fileExplorerLanguage.value,
            _sort: '-_lastUpdated',
            ...(fileExplorerStatus !== FileExplorerStatus.ALL && { status: fileExplorerStatus }),
          }
          // `based-on=List/${fileExplorerSelectedCategoryId}&identifier=${fileExplorerLanguage.value}&_sort=-_lastUpdated`
        )
        ?.then(
          (resp) =>
            resp?.entry
              ?.flatMap((e) => e?.resource)
              ?.filter(
                (item) => item?.payload?.some((p) => p?.contentString?.toLowerCase().includes(fileExplorerSearchText))
              )
        ),
    {
      ...options,
      enabled: !!fileExplorerSelectedCategoryId,
    }
  );
};
