/* eslint-disable  @typescript-eslint/ban-ts-comment */
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { BundleEntry, Communication } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { v4 as uuidv4 } from 'uuid';
import { useQuotesStore } from '../../store/quote/useQuotesStore';
import { sourceLanguage } from '../../utils/content/contentUtils';

export interface ICreateUpdateQuoteProps {
  type: 'create' | 'update';
}

export const useCreateUpdateQuote = (
  options?: Omit<UseMutationOptions<any, any, any, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<any, Error, ICreateUpdateQuoteProps, any> => {
  const medplum = useMedplum();
  const quotes = useQuotesStore((s) => s.quotes);
  const quoteAuthor = useQuotesStore((s) => s.quoteAuthor);
  const quoteCategory = useQuotesStore((s) => s.quoteCategory);
  const generatedQuoteId = uuidv4();

  return useMutation(async (props: ICreateUpdateQuoteProps) => {
    // Here we need to ignore status type of the Communication resource because We use custom status types.
    // @ts-ignore
    const quoteBodies: Communication[] = quotes.map((q) => {
      return {
        id: q?.id ?? undefined,
        resourceType: 'Communication',
        identifier: [
          {
            system: 'language-code',
            value: q?.language?.value,
          },
        ],
        partOf:
          q?.language?.value !== sourceLanguage?.value
            ? [
                {
                  reference:
                    props.type === 'update'
                      ? `Communication/${quotes?.find((q) => !!q?.isSource)?.id}`
                      : `urn:uuid:${generatedQuoteId}`,
                },
              ]
            : undefined,
        payload: [
          {
            contentString: q?.quoteText,
          },
          {
            contentString: quoteAuthor,
          },
        ],
        basedOn: [
          {
            reference: `List/${quoteCategory}`,
          },
        ],
        status: q.status ?? 'draft',
      };
    });

    const quoteEntries: BundleEntry<Communication>[] = quoteBodies.map((x: Communication) => {
      return {
        fullUrl: props.type === 'update' ? '' : `urn:uuid:${generatedQuoteId}`,
        request: {
          method: props.type === 'update' ? 'PUT' : 'POST',
          url: props.type === 'update' ? `/Communication/${x.id}` : '/Communication',
        },
        resource: {
          ...x,
        },
      };
    });

    const bundleEntries: BundleEntry<Communication>[] = [...quoteEntries];

    return medplum.executeBatch({
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    });
  }, options);
};
