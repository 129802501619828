import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from '../query-keys';
import { CatalogEntry } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

const tagKeys = {
  all: [QueryKeys.GET_TAGS_BY_NAME] as const,
  byParams: (searchValue: string) => [...tagKeys.all, searchValue],
};

export const useGetTagsByName = (
  searchValue: string,
  options?: Omit<UseQueryOptions<any, any, any, any>, 'mutationFn' | 'mutationKey'>
): UseQueryResult<any> => {
  const medplum = useMedplum();
  return useQuery(
    tagKeys.byParams(searchValue),
    async () =>
      medplum
        .search(`CatalogEntry`, `_count=1000`, { cache: 'reload' })
        .then((res) => mapToResult(res?.entry?.map((x) => x?.resource))),
    options
  );
};

const mapToResult = (data: any): any => {
  return data?.map((item: CatalogEntry) => ({
    value: item?.id,
    label: item?.type?.coding?.[0]?.display,
  }));
};
