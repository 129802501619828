import { Box, Button, Card, Flex, Grid, Text, Title, useMantineTheme } from '@mantine/core';
import React from 'react';
import { Communication } from '@medplum/fhirtypes';
import { IconQuote, IconTrash } from '@tabler/icons-react';

interface Props {
  quotesData: Communication[];
  onClick: (id: string) => void;
  onDelete: (id: string) => void;
}

const QuotesGrid: React.FC<Props> = ({ quotesData, onClick, onDelete }) => {
  const theme = useMantineTheme();

  return (
    <Box px={12}>
      <Title order={3}>Quotes</Title>
      <Text c={theme.colors.gray[6]} mb={24}>
        {quotesData?.length <= 0 ? 'No' : quotesData?.length} quote(s) found.
      </Text>
      <Grid>
        {quotesData?.map((quote: Communication) => {
          return (
            <Grid.Col span={{ xs: 12, sm: 6, md: 3, xl: 2 }}>
              <Card h={280} shadow="sm" padding="lg" radius="md" withBorder pos={'relative'}>
                <Flex w={'100%'} h={'100%'} direction={'column'} justify="space-between">
                  <Flex w={'100%'} direction={'column'}>
                    <IconQuote />
                    <Text style={{ fontStyle: 'italic' }} fw={'300'} my={12}>
                      {quote?.payload?.[0]?.contentString?.substring(0, 100)}
                    </Text>
                    <Text fw={'bold'}>- {quote?.payload?.[1]?.contentString}</Text>
                  </Flex>

                  <Flex w={'100%'} direction={'row'} align="center" justify="space-between">
                    <Button onClick={() => onClick(String(quote?.id))} variant="light" color="blue" mt="md" radius="md">
                      Edit
                    </Button>
                    <Button
                      onClick={() => onDelete(String(quote?.id))}
                      variant="light"
                      color="red"
                      mt="md"
                      radius="md"
                      size="sm"
                    >
                      <IconTrash size={18} />
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
};

export default QuotesGrid;
