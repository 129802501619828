import {
  ActionIcon,
  Button,
  Center,
  Flex,
  LoadingOverlay,
  Modal,
  NativeSelect,
  Tabs,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { sourceLanguage, targetLangs } from '../utils/content/contentUtils';
import { IconPlus } from '@tabler/icons-react';
import { useQuotesStore } from '../store/quote/useQuotesStore';
import { useCreateUpdateQuote } from '../queries/quotes/useCreateUpdateQuote';
import { List } from '@medplum/fhirtypes';
import { useGetQuotesById } from '../queries/quotes/useGetQuotesById';
import { useGetCategories } from '../queries/content/useGetCategories';
import { FileExplorerIdentifier } from '../file-explorer/FileExplorer';
import { useEffect } from 'react';
import { FileExplorerStatus, fileExplorerStatusArr } from '../store/file-explorer/useFileExplorerStore';

export interface AddEditQuoteModalProps {
  type: 'create' | 'update';
  editQuoteId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  handleOpenAddLanguageModal: () => void;
  handleChangeTab: (val: string | null) => void;
  selectedLanguage: { value: string; label: string };
}

export function AddEditQuoteModal({
  type,
  editQuoteId,
  isOpen,
  onClose,
  handleOpenAddLanguageModal,
  handleChangeTab,
  selectedLanguage,
}: AddEditQuoteModalProps): JSX.Element | null {
  const theme = useMantineTheme();

  const { data: categoriesData } = useGetCategories(FileExplorerIdentifier.QUOTE_CATEGORY, {
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (type === 'create' && !!categoriesData) {
      setQuoteCategory(categoriesData?.[0]?.id as string);
    }
  }, [isOpen]);

  const quoteAuthor = useQuotesStore((s) => s.quoteAuthor);
  const quoteCategory = useQuotesStore((s) => s.quoteCategory);
  const quotes = useQuotesStore((s) => s.quotes);
  const setQuotes = useQuotesStore((s) => s.handleSetValues);
  const setQuoteAuthor = useQuotesStore((s) => s.handleSetQuoteAuthor);
  const setQuoteCategory = useQuotesStore((s) => s.handleSetQuoteCategory);

  useGetQuotesById(editQuoteId as string, {
    enabled: !!editQuoteId,
    onSuccess: (resp) => {
      const quotesResp = resp?.map((r) => {
        const langCode = r?.identifier?.find((i) => i?.system === 'language-code')?.value;
        return {
          id: r?.id as string,
          isSource: langCode === sourceLanguage?.value,
          language: { label: targetLangs?.find((t) => t.value === langCode)?.label ?? '', value: langCode ?? '' },
          quoteText: r?.payload?.[0]?.contentString ?? '',
          status: r?.status as FileExplorerStatus,
        };
      });

      setQuotes(quotesResp);
      setQuoteAuthor(resp?.[0]?.payload?.[1]?.contentString ?? '');
      setQuoteCategory(resp?.[0]?.basedOn?.[0]?.reference?.split('/')?.[1] ?? '');
    },
  });

  const handleSetQuoteText = (value: string): void => {
    const allQuotes = [...quotes];
    const findQuote = allQuotes.find((x) => x.language.value === selectedLanguage.value);

    if (findQuote) {
      findQuote.quoteText = value;
    }

    setQuotes(allQuotes);
  };

  const handleSetQuoteAuthor = (value: string): void => {
    setQuoteAuthor(value);
  };

  const handleSetQuoteCategory = (value: string): void => {
    setQuoteCategory(value);
  };

  const handleSetQuoteStatus = (value: FileExplorerStatus): void => {
    const allQuotes = [...quotes];
    const findQuote = allQuotes.find((x) => x.language.value === selectedLanguage.value);

    if (findQuote) {
      findQuote.status = value;
    }

    setQuotes(allQuotes);
  };

  const { mutateAsync: createOrUpdateQuote, isLoading } = useCreateUpdateQuote({
    onSettled: () => {
      onClose();
      window.location.reload();
    },
  });

  const handleCreateUpdateQuote = async (): Promise<void> => {
    await createOrUpdateQuote({ type });
  };

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Modal
      title={type === 'update' ? 'Edit quote' : 'Create a quote'}
      opened={isOpen}
      onClose={onClose}
      closeOnClickOutside
      centered
      size="md"
      zIndex={102}
    >
      <Center inline style={{ width: '100%', justifyContent: 'flex-start' }}>
        <Tabs onChange={(newValue) => handleChangeTab(newValue)} value={selectedLanguage.value}>
          <Tabs.List>
            {quotes?.map((x) => (
              <Tabs.Tab key={x?.language?.value} value={x?.language.value}>
                <Text color={theme.primaryColor}>{x?.language?.label}</Text>
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
        <Tooltip label="Add language">
          <ActionIcon
            disabled={quotes?.length === targetLangs.length}
            onClick={handleOpenAddLanguageModal}
            size="md"
            color={theme.primaryColor}
          >
            <IconPlus size={20} />
          </ActionIcon>
        </Tooltip>
      </Center>
      <Flex direction="column">
        <Flex direction="column" my={18}>
          <Textarea
            size="md"
            style={{ width: '100%' }}
            mb={12}
            rows={5}
            label="Quote"
            value={quotes.find((x) => x.language.value === selectedLanguage.value)?.quoteText}
            placeholder="Write a quote"
            onChange={(e) => handleSetQuoteText(e.target.value)}
          />
          <TextInput
            size="md"
            style={{ width: '100%' }}
            mb={12}
            label="Author"
            value={quoteAuthor}
            placeholder="Author"
            onChange={(e) => handleSetQuoteAuthor(e.target.value)}
          />
          {!!categoriesData && (
            <NativeSelect
              label="Category"
              mb={12}
              defaultValue={categoriesData?.[0]?.id}
              value={quoteCategory}
              data={categoriesData?.map((c: List) => {
                return {
                  label: c?.title ?? '',
                  value: c?.id ?? '',
                };
              })}
              onChange={(e) => handleSetQuoteCategory(e.target.value)}
            />
          )}
          <NativeSelect
            label="Status"
            defaultValue={fileExplorerStatusArr?.[0]?.value}
            value={quotes.find((x) => x.language.value === selectedLanguage.value)?.status}
            data={fileExplorerStatusArr?.map((c) => {
              return {
                label: c?.label,
                value: c?.value,
              };
            })}
            onChange={(e) => handleSetQuoteStatus(e.target.value as FileExplorerStatus)}
          />
        </Flex>
        <Flex align="center" justify="flex-end" direction="row" mt={18}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={handleCreateUpdateQuote} variant="filled" bg={theme.primaryColor} ml={8}>
            {type === 'update' ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
