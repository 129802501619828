import { CodeableConcept, Coding, CompositionRelatesTo } from '@medplum/fhirtypes';
import { EditorState } from 'draft-js';

export enum ContentStatusType {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFT = 'draft',
}

export enum ContentFormType {
  CREATE = 'create',
  UPDATE = 'update',
}

export type IContents = {
  id?: string | undefined;
  isSource: boolean;
  language: { label: string; value: string };
  languageLabel: string;
  content: EditorState;
  title: string;
  description: string;
  status: ContentStatusType;
};

export type ContentSchema = {
  contents: IContents[];
  contentId: string;
  categoryId: string;
  returnedCoverImgUrl?: string;
  coverImageFile: File | null;
  tags: {
    value: string;
    label: string;
  }[];
  status: ContentStatusType;
  formType: ContentFormType;
};

export interface Content {
  id: string;
  description: string;
  title: string;
  date: string;
  content: EditorState;
  categoryId: string;
  coverImage: string;
  language?: string;
  status: ContentStatusType;
  relatesTo: CompositionRelatesTo[]; // must be CompositionRelatesTo[]
  section: ISection[]; // must be ISection[]
  tags: {
    value: string;
    label: string;
  }[];
}

export interface IFhirResponse {
  event: {
    code: CodeableConcept[];
    detail: {
      reference: string;
    };
  }[];
  subject: {
    reference: string;
  };
  meta: {
    createdAt: string;
    lastUpdated: string;
  };
  id: any;
  text: { div: any };
  title: any;
  section: any;
  date: any;
  status: any;
  relatesTo: CompositionRelatesTo[];
  language: string;
}

export interface ISection {
  code: {
    id: string;
    coding: Coding[];
  };
  text: {
    div: string;
  };
  title: string;
  section: ISection[];
  entry: [
    {
      display: string;
    },
  ];
}
