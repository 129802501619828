import { Alert, Button, Flex, Modal, Select, Text, useMantineTheme } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { targetLangs } from '../utils/content/contentUtils';

export interface AddLanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedLanguage: { value: string; label: string };
  maxCharacterLimit: number;
  hasCharacterLimitError: boolean | undefined;
  isTranslationLoading: boolean;
  handleAddLanguage: () => void;
  setSelectedLanguage: (lang: { value: string; label: string }) => void;
}

export function AddLanguageModal({
  isOpen,
  onClose,
  selectedLanguage,
  maxCharacterLimit,
  hasCharacterLimitError,
  isTranslationLoading,
  setSelectedLanguage,
  handleAddLanguage,
}: AddLanguageModalProps): JSX.Element | null {
  const theme = useMantineTheme();

  return (
    <Modal title="Add language" opened={isOpen} onClose={onClose} centered size="auto" zIndex={103}>
      <Flex direction="column" p={4}>
        <Select
          // disabled={disabled}
          size="md"
          value={selectedLanguage.value}
          onChange={(val: string | null) =>
            setSelectedLanguage({ label: targetLangs.find((x) => x.value === val)?.label ?? '', value: val ?? '' })
          }
          label="Select a language"
          placeholder="Status"
          error={hasCharacterLimitError}
          data={
            targetLangs?.map((f) => {
              return { value: f?.value, label: f?.label };
            }) ?? []
          }
        />
        {hasCharacterLimitError && (
          <Text color="error" variant="body2" mt={1}>
            Max character limit to translate the content is {maxCharacterLimit}
          </Text>
        )}
        {/* 
      <Select error={hasCharacterLimitError} fullWidth value={selectedLanguage.value} label="Select a language">
        {targetLangs.map((lang) => (
          <MenuItem key={lang.value} onClick={() => setSelectedLanguage(lang)} value={lang.value}>
            {lang.label}
          </MenuItem>
        ))}
      </Select> */}

        <Flex my={8} align="center" direction="row" p={2}>
          <Alert icon={<IconAlertCircle size="1rem" />} color={theme.colors.yellow[6]}>
            Please add the language after you finalize your content with the source language.
          </Alert>
        </Flex>
        <Flex align="center" direction="row" p={2}>
          <Alert icon={<IconAlertCircle size="1rem" />} color={theme.colors.yellow[6]} style={{ mb: 6 }}>
            Max character limit to translate the content is {maxCharacterLimit}.
          </Alert>
        </Flex>

        <Flex align="center" justify="flex-end" direction="row" py={8}>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
          <Button
            disabled={hasCharacterLimitError}
            loading={isTranslationLoading}
            onClick={handleAddLanguage}
            variant="filled"
            ml={8}
          >
            Add
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
