export enum QueryKeys {
  GET_LIST_ITERATION = 'get-list-iteration',
  GET_CONTENTS = 'contents',
  GET_CONTENT_CATEGORIES = 'content-categories',
  GET_QUOTE_CATEGORIES = 'quote-categories',
  GET_QUOTES = 'get-quotes',
  GET_QUOTES_BY_ID = 'get-quotes-by-id',
  GET_CONTENT_DETAIL = 'content-detail',
  GET_TAGS_BY_ID = 'get-tags-by-id',
  GET_TAGS_BY_NAME = 'get-tags-by-name',
  TRANSLATE_HTML = 'translate-html',
}
