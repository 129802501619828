import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';
import { Communication } from '@medplum/fhirtypes';

export const useGetQuotesById = (
  quoteId: string,
  options?: UseQueryOptions<Communication[], Error>
): UseQueryResult<any> => {
  const medplum = useMedplum();

  return useQuery<Communication[], Error>(
    [QueryKeys.GET_QUOTES_BY_ID, quoteId],
    async () =>
      medplum
        .search('Communication', {
          _id: quoteId,
          _revinclude: 'Communication:part-of:Communication',
        })
        ?.then((resp) => resp?.entry?.map((e) => e?.resource) as Communication[]),
    options
  );
};
