/* eslint-disable  @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Group,
  Image,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import { useContentStore } from '../store/content/useContentStore';
import { Coding, Composition } from '@medplum/fhirtypes';
import { sourceLanguage } from '../utils/content/contentUtils';
import { IconBook, IconTrash } from '@tabler/icons-react';
import { useDeleteComposition } from '../queries/content/useDeleteComposition';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../queries/query-keys';

interface Props {
  contentsData: Composition[];
  onClick: (id: string) => void;
}

const ContentGrid: React.FC<Props> = ({ contentsData, onClick }) => {
  const theme = useMantineTheme();
  const queryClient = useQueryClient();

  const { selectedContents, setSelectedContents } = useContentStore((x) => ({
    selectedContents: x.selectedContents,
    setSelectedContents: x.setSelectedContents,
  }));

  const toggleSelectedContent = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    e.stopPropagation();
    if (selectedContents.includes(id)) {
      const findRelatedContents = contentsData
        ?.filter((x: Composition) => x?.language !== sourceLanguage.value)
        ?.filter((y: any) => y?.relatesTo?.[0]?.targetReference?.id === id);

      const relatedIds = (findRelatedContents?.map((x: Composition) => x?.id) as string[]) ?? [];
      const ids = [id, ...relatedIds];
      setSelectedContents(selectedContents.filter((x) => !ids.includes(x)));
    } else {
      const findRelatedContents = contentsData
        ?.filter((x: Composition) => x?.language !== sourceLanguage.value)
        ?.filter((y: any) => y?.relatesTo?.[0]?.targetReference?.id === id);

      const relatedIds = (findRelatedContents?.map((x: Composition) => x?.id) as string[]) ?? [];
      const ids = [id, ...relatedIds];
      setSelectedContents([...selectedContents, ...ids]);
    }
  };

  const { mutateAsync: delComposition } = useDeleteComposition({
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.GET_CONTENTS);
    },
  });

  const handleDeleteContent = async (id: string): Promise<void> => {
    if (confirm('Are you sure to delete this content?')) {
      await delComposition(id);
    }
  };

  return (
    <Box px={12}>
      <Title order={3}>Contents</Title>
      <Text c={theme.colors.gray[6]} mb={24}>
        {contentsData?.length <= 0 ? 'No' : contentsData?.length} content(s) found.
      </Text>
      <Grid>
        {contentsData?.map((content: Composition) => {
          const coverImageUrl = content?.section?.find((x) => x?.code?.id === 'cover-image')?.entry?.[0]?.display;
          const readingTimeCode = content?.section?.[content?.section?.length - 1]?.code?.coding?.find(
            (c: Coding) => c?.code === 'reading-time'
          );

          return (
            <Grid.Col span={{ xs: 12, sm: 6, md: 3, xl: 2 }}>
              <Card shadow="sm" padding="lg" radius="md" withBorder pos={'relative'}>
                <Card.Section>
                  <Checkbox
                    checked={selectedContents?.includes(String(content?.id))}
                    onChange={(e) => toggleSelectedContent(e, String(content?.id))}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      position: 'absolute',
                      top: 4,
                      left: 4,
                      alignSelf: 'flex-start',
                      marginBottom: 8,
                    }}
                  />
                  <Image src={coverImageUrl} height={160} alt="Norway" />
                  <Badge
                    size="xs"
                    style={{ position: 'absolute', top: 5, right: 5 }}
                    // We use custom status values. Thats why the lint error is ignored here.
                    //@ts-ignore
                    color={content?.status === 'active' ? 'green' : content?.status === 'draft' ? 'yellow' : 'red'}
                  >
                    {content?.status}
                  </Badge>
                  <Badge size="xs" style={{ position: 'absolute', top: 30, right: 5 }} color={theme.colors.blue[6]}>
                    {content?.language}
                  </Badge>
                </Card.Section>

                <Group mt="md" mb="xs">
                  <Tooltip label={content?.title}>
                    <Text style={{ cursor: 'default' }} fw={'bold'}>
                      {content?.title?.substring(0, 15)}...
                    </Text>
                  </Tooltip>
                  {readingTimeCode && (
                    <Badge leftSection={<IconBook size={12} />} variant="light" color={theme.colors.gray[6]}>
                      {readingTimeCode?.display}
                    </Badge>
                  )}
                </Group>

                <Flex w={'100%'} direction={'row'} align="center" justify="space-between">
                  <Button onClick={() => onClick(String(content?.id))} variant="light" color="blue" mt="md" radius="md">
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleDeleteContent(String(content?.id))}
                    variant="light"
                    color="red"
                    mt="md"
                    radius="md"
                    size="sm"
                  >
                    <IconTrash size={18} />
                  </Button>
                </Flex>
              </Card>
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ContentGrid;
