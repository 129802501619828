import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';
import { Composition } from '@medplum/fhirtypes';
import { FileExplorerStatus, useFileExplorerStore } from '../../store/file-explorer/useFileExplorerStore';

export const useGetContents = (options?: UseQueryOptions<Composition[], Error>): UseQueryResult<any> => {
  const medplum = useMedplum();

  const fileExplorerSearchText = useFileExplorerStore((s) => s.fileExplorerSearchText);
  const fileExplorerLanguage = useFileExplorerStore((s) => s.fileExplorerLanguage);
  const fileExplorerStatus = useFileExplorerStore((s) => s.fileExplorerStatus);
  const fileExplorerSelectedCategoryId = useFileExplorerStore((s) => s.fileExplorerSelectedCategoryId);

  return useQuery<Composition[], Error>(
    [
      QueryKeys.GET_CONTENTS,
      fileExplorerSelectedCategoryId,
      fileExplorerLanguage,
      fileExplorerStatus,
      fileExplorerSearchText,
      fileExplorerLanguage,
    ],
    async () =>
      medplum.searchResources(
        'Composition',
        {
          subject: `List/${fileExplorerSelectedCategoryId}`,
          category: fileExplorerLanguage.value,
          title: fileExplorerSearchText ?? '',
          ...(fileExplorerStatus !== FileExplorerStatus.ALL && { status: fileExplorerStatus }),
          _sort: '-_lastUpdated',
        },
        {
          cache: 'reload',
        }
      ),
    {
      ...options,
      enabled: !!fileExplorerSelectedCategoryId,
      retry: false,
    }
  );
};
