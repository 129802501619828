import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '../query-keys';
import { Coding, List } from '@medplum/fhirtypes';
import { ListCode } from '../../types/list/listTypes';
import { useMedplum } from '@medplum/react';
import { FileExplorerIdentifier } from '../../file-explorer/FileExplorer';

interface CreateUpdateCategoryData {
  type: 'create' | 'update';
  identifier: FileExplorerIdentifier;
  coding: Coding[];
  parentListId?: string;
  selectedListToUpdate?: List;
}

export const useCreateUpdateCategory = (
  options?: Omit<UseMutationOptions<any, Error, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<any, Error, CreateUpdateCategoryData, any> => {
  const queryClient = useQueryClient();
  const medplum = useMedplum();
  const projectId = medplum.getProject()?.id;

  return useMutation(async ({ type, coding, selectedListToUpdate, parentListId }: CreateUpdateCategoryData) => {
    const body: List = {
      title: coding?.[0]?.display ?? '',
      entry: [],
      orderedBy: {
        coding: [
          {
            code: 'entry-date',
            system: 'http://terminology.hl7.org/CodeSystem/list-order',
          },
        ],
      },
      mode: 'working',
      resourceType: 'List',
      source: {
        reference: `Project/${projectId}`,
      },
      code: {
        coding,
      },
      identifier: [
        {
          system: 'category',
          value: 'category',
        },
      ],
      status: 'current',
    };

    if (type === 'update' && selectedListToUpdate) {
      return medplum.updateResource({
        ...selectedListToUpdate,
        resourceType: 'List',
        id: selectedListToUpdate?.id,
        code: {
          coding,
        },
      });
    } else {
      return medplum.createResource(body).then(async (res: any) => {
        if (!parentListId) {
          return;
        }

        const parentListArray = await queryClient.getQueryData<List[]>([
          QueryKeys.GET_LIST_ITERATION,
          projectId,
          ListCode.CONTENTS,
        ]);

        const parentList = parentListArray?.find((x) => x.id === parentListId);

        if (!parentList) {
          return;
        }

        const parentEntry = [
          ...(parentList.entry ?? []),
          {
            date: new Date().toISOString(),
            flag: {
              coding: [
                {
                  display: 'Sub Folder',
                },
              ],
            },
            item: {
              display: coding?.[0]?.display ?? '',
              id: res.id ?? '',
              resourceType: 'List',
            },
          },
        ];

        await medplum.updateResource({
          ...parentList,
          id: parentList.id,
          entry: parentEntry,
        });
      });
    }
  }, options);
};
