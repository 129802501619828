import { Box, Table, Text, Title, useMantineTheme } from '@mantine/core';
import React from 'react';
import moment from 'moment';
import { Communication, Identifier } from '@medplum/fhirtypes';
import { targetLangs } from '../utils/content/contentUtils';

interface Props {
  quotesData: Communication[];
}

const QuotesTable: React.FC<Props> = ({ quotesData }) => {
  const theme = useMantineTheme();

  const rows = quotesData?.map((c: any) => (
    <Table.Tr key={c?.id}>
      <Table.Td>{c?.payload?.[0]?.contentString}</Table.Td>
      <Table.Td>{c?.payload?.[1]?.contentString}</Table.Td>
      <Table.Td style={{ textTransform: 'capitalize' }}>{c?.status}</Table.Td>
      <Table.Td>
        {
          targetLangs?.find(
            (t) => t?.value === c?.identifier?.find((i: Identifier) => i?.system === 'language-code')?.value
          )?.label
        }{' '}
      </Table.Td>
      <Table.Td>{moment(c?.date).format('DD MMM YYYY, HH:mm')}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Box px={12}>
      <Title order={3}>Quotes</Title>
      <Text c={theme.colors.gray[6]} mb={24}>
        {quotesData?.length <= 0 ? 'No' : quotesData?.length} quote(s) found.
      </Text>
      <Table withTableBorder striped highlightOnHover withColumnBorders withRowBorders verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Quote</Table.Th>
            <Table.Th>Author</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Language</Table.Th>
            <Table.Th>Last modified</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Box>
  );
};

export default QuotesTable;
