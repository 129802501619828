/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { BundleEntry, Communication } from '@medplum/fhirtypes';

export interface IDeleteQuoteProps {
  id: string;
}

export const useDeleteQuote = (
  options?: Omit<UseMutationOptions<any, any, any, any>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();

  return useMutation(async ({ id }: IDeleteQuoteProps) => {
    const getRelatedQuotes = await medplum
      .search('Communication', `part-of=Communication/${id}`)
      ?.then((resp) => resp?.entry?.map((e) => e?.resource) as Communication[]);

    const relatedQuotesBundleEntries: BundleEntry<Communication>[] = getRelatedQuotes?.map((q) => {
      return {
        request: {
          method: 'DELETE',
          url: `/Communication/${q?.id}`,
        },
      };
    });

    const bundleEntries: BundleEntry<Communication>[] = [
      ...relatedQuotesBundleEntries,
      {
        request: {
          method: 'DELETE',
          url: `/Communication/${id}`,
        },
      },
    ];

    return medplum.executeBatch({
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    });
  }, options);
};
