import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { QueryKeys } from '../query-keys';
import { BundleEntry, List } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

export const useGetCategories = (
  identifier: string,
  options?: UseQueryOptions<List[] | undefined, Error>
): UseQueryResult<any> => {
  const medplum = useMedplum();
  return useQuery<List[] | undefined, Error>(
    [QueryKeys.GET_CONTENT_CATEGORIES, identifier],
    async () =>
      (
        await medplum.searchResources('List', `identifier=${identifier}`, {
          cache: 'reload',
        })
      )?.bundle?.entry?.map((x: BundleEntry) => x?.resource as List),
    options
  );
};
