import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { CatalogEntry } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

interface IData {
  title: string;
}

export const useCreateTag = (
  options?: Omit<UseMutationOptions<any, Error, any>, 'mutationFn' | 'mutationKey'>
): UseMutationResult<any, Error, IData, any> => {
  const medplum = useMedplum();
  const projectId = medplum.getProject()?.id;

  return useMutation((data: IData) => {
    const body: CatalogEntry = {
      resourceType: 'CatalogEntry',
      type: {
        coding: [
          {
            display: data.title,
          },
        ],
      },
      orderable: true,
      identifier: [
        {
          value: 'tag',
        },
      ],
      referencedItem: {
        reference: `Project/${projectId}`,
      },
    };

    return medplum.createResource(body);
  }, options);
};
