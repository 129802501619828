import * as React from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { ContentFormType, ContentStatusType, IContents } from '../types/content/contentTypes';
import { IDeeplTranslationBody, useGetDeeplTranslation } from '../queries/content/useGetDeeplTranslation';
import { useDeleteComposition } from '../queries/content/useDeleteComposition';
import { ActionIcon, Button, Center, Flex, Grid, Modal, Tabs, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import htmlToDraft from 'html-to-draftjs';
import { ContentInfoCard } from './ContentInfoCard';
import { UploadImage } from './UploadImage';
import { ContentEditor } from './ContentEditor';
import { useContentStore } from '../store/content/useContentStore';
import { sourceLanguage, targetLangs } from '../utils/content/contentUtils';
import { AddLanguageModal } from '../components/AddLanguageModal';

const MAX_TRANSLATION_CHARACTER_LIMIT = 10000;
interface ContentFormProps {
  onSubmit: () => void;
  selectedLanguage: { label: string; value: string };
  setSelectedLanguage: (val: { label: string; value: string }) => void;
}

export const ContentForm: React.FC<ContentFormProps> = ({ onSubmit, selectedLanguage, setSelectedLanguage }) => {
  const theme = useMantineTheme();

  const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = React.useState<boolean>(false);
  const [isRemoveLanguageModalOpen, setIsRemoveLanguageModalOpen] = React.useState(false);
  const [removeLangId, setRemoveLangId] = React.useState<string | undefined>(undefined);
  const [isDeletingLanguage, setIsDeletingLanguage] = React.useState(false);

  //Store
  const values = useContentStore((s) => s.values);
  const setValues = useContentStore((s) => s.handleSetValues);

  const MAX_CHARACTER_LIMIT: number = React.useMemo(() => {
    const findSourceTitleLength = values.contents?.find((x) => x.isSource)?.title.length;
    const findSourceDescLength = values.contents?.find((x) => x.isSource)?.description.length;

    return MAX_TRANSLATION_CHARACTER_LIMIT - (findSourceTitleLength ?? 0) - (findSourceDescLength ?? 0);
  }, [values.contents]);

  const handleChangeCoverImage = (file: File): void => {
    setValues({ ...values, coverImageFile: file });
  };

  const handleChangeContent = (content: EditorState, language: string): void => {
    if (content.getCurrentContent().getPlainText().length > MAX_CHARACTER_LIMIT) {
      // enqueueSnackbar(`Max character limit to translate the content is ${MAX_CHARACTER_LIMIT}`, { variant: 'error' })

      return;
    }

    const updatedContents = values.contents.map((c) => {
      if (c.language.value === language) {
        return {
          ...c,
          content,
        };
      }

      return c;
    });

    setValues({ ...values, contents: updatedContents });
  };

  const handleChangeTab = (newValue: any): void => {
    const selected = targetLangs.find((x) => x.value === newValue);
    setSelectedLanguage(selected as { label: string; value: string });
  };

  const { mutateAsync: translateFromDeepl, isLoading: isTranslationLoading } = useGetDeeplTranslation({
    onError: () => {
      setIsAddLanguageModalOpen(false);
    },
  });

  const { mutateAsync: deleteComposition, isLoading: isRemoveLoading } = useDeleteComposition({
    onSettled: () => {
      setRemoveLangId(undefined);
      setIsRemoveLanguageModalOpen(false);
      setSelectedLanguage({ label: 'German', value: sourceLanguage.value });
    },
  });

  const handleAddLanguage = async (): Promise<void> => {
    if (values.contents.find((x) => x.language.value === selectedLanguage.value)) {
      return;
    }

    let currentContent = values.contents
      .find((x) => x?.language?.value === sourceLanguage.value)
      ?.content.getCurrentContent();

    if (!currentContent) {
      currentContent = EditorState.createEmpty().getCurrentContent();
    }

    const bodyForDeepl: IDeeplTranslationBody = {
      text: [
        `${values.contents.find((x) => x?.language?.value === sourceLanguage.value)?.title}`,
        `${values.contents.find((x) => x?.language?.value === sourceLanguage.value)?.description}`,
        `${draftToHtml(convertToRaw(currentContent))}`,
      ],
      targetLanguage: selectedLanguage.value,
      sourceLanguage: sourceLanguage.value,
      options: {
        tagHandling: 'html',
      },
    };

    const translatedContent: any = await translateFromDeepl({ body: bodyForDeepl });

    let editorState: any;

    const translatedContentTitle = translatedContent?.[0]?.text;
    const translatedContentDescription = translatedContent?.[1]?.text;

    const contentBlock = htmlToDraft(translatedContent?.[2]?.text);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    }

    const newContent: IContents = {
      isSource: false,
      language: selectedLanguage,
      title: translatedContentTitle,
      description: translatedContentDescription,
      languageLabel: targetLangs.find((x) => x.value === selectedLanguage.value)?.label ?? '',
      content: editorState,
      status: ContentStatusType.DRAFT,
    };

    setValues({ ...values, contents: [...values.contents, newContent] });
    // setFieldValue('contents', [...values.contents, newContent]);
    setIsAddLanguageModalOpen(false);
  };

  const handleRemoveLanguage = async (): Promise<void> => {
    if (values.formType === ContentFormType.UPDATE && removeLangId) {
      await deleteComposition(removeLangId);
    } else {
      setIsDeletingLanguage(true);
      const existingContents = _.cloneDeep(values.contents);
      const newContents = existingContents.filter((x) => x.language.value !== selectedLanguage.value);
      setValues({ ...values, contents: [...newContents] });
      // setFieldValue('contents', [...newContents]);
      setSelectedLanguage({ label: 'German', value: sourceLanguage.value });
      setIsDeletingLanguage(false);
      setIsRemoveLanguageModalOpen(false);
    }
  };

  const hasCharacterLimitError = React.useMemo(() => {
    const content = values?.contents?.find((x) => x?.isSource)?.content;

    if (content) {
      return content.getCurrentContent().getPlainText().length > MAX_CHARACTER_LIMIT;
    } else {
      return undefined;
    }
  }, [values.contents]);

  const sourceContent = React.useMemo(
    () => values?.contents?.find((x) => x?.language?.value === 'de'),
    [values.contents]
  );

  const sourceContentLength = React.useMemo(() => {
    return sourceContent?.content?.getCurrentContent()?.getPlainText()?.length ?? 0;
  }, [values.contents]);

  return (
    <Grid p={12}>
      <Grid.Col span={12}>
        <Center inline style={{ width: '100%', justifyContent: 'flex-start' }}>
          <Tabs onChange={(newValue) => handleChangeTab(newValue)} value={selectedLanguage.value}>
            <Tabs.List>
              {values?.contents?.map((x) => (
                <Tabs.Tab key={x?.language?.value} value={x?.language?.value}>
                  <Text fw={'bold'} color={theme.primaryColor}>
                    {x?.languageLabel}
                  </Text>
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
          <Tooltip label="Add language">
            <ActionIcon
              ml={12}
              disabled={values.contents?.length === targetLangs.length}
              onClick={() => setIsAddLanguageModalOpen(true)}
              size="md"
              variant="light"
              color={theme.primaryColor}
            >
              <IconPlus size={20} />
            </ActionIcon>
          </Tooltip>
        </Center>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, mb: 4 }}>
        <ContentInfoCard
          value={
            values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.content ??
            EditorState.createEmpty()
          }
          publishDisabled={
            (!values.coverImageFile && !values.returnedCoverImgUrl) ||
            !values.categoryId ||
            sourceContentLength < 1 ||
            !sourceContent?.title?.length
          }
          isSourceLanguage={selectedLanguage.value === sourceLanguage.value}
          selectedLanguage={selectedLanguage}
          handleCreateContent={onSubmit}
          handleRemoveLanguage={() => {
            setRemoveLangId(
              values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.id ?? undefined
            );
            setIsRemoveLanguageModalOpen(true);
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 4 }} style={{ maxHeight: 430 }}>
        <UploadImage
          onChange={handleChangeCoverImage}
          imageUrl={values.coverImageFile ? URL.createObjectURL(values.coverImageFile) : values.returnedCoverImgUrl}
          errorText={''}
        />
      </Grid.Col>

      <Grid.Col span={{ xs: 12, md: 8 }} pl={2} style={{ overflow: 'visible' }}>
        <ContentEditor
          maxCharacterLimit={MAX_CHARACTER_LIMIT}
          isSource={values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.isSource ?? false}
          selectedLanguage={selectedLanguage?.value}
          value={
            values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.content ??
            EditorState.createEmpty()
          }
          onChange={(content: EditorState, language: string) => handleChangeContent(content, language)}
        />
      </Grid.Col>

      {/* ADD LANGUAGE MODAL */}
      <AddLanguageModal
        isOpen={isAddLanguageModalOpen}
        onClose={() => setIsAddLanguageModalOpen(false)}
        selectedLanguage={selectedLanguage}
        isTranslationLoading={isTranslationLoading}
        maxCharacterLimit={MAX_CHARACTER_LIMIT}
        handleAddLanguage={handleAddLanguage}
        setSelectedLanguage={(val: { value: string; label: string }) => setSelectedLanguage(val)}
        hasCharacterLimitError={hasCharacterLimitError}
      />

      {/* REMOVE LANGUAGE MODAL */}
      <Modal
        title="Remove language"
        opened={isRemoveLanguageModalOpen}
        onClose={() => setIsRemoveLanguageModalOpen(false)}
        centered
        size="auto"
      >
        <Flex direction="column">
          <Text>Are you sure to remove this language?</Text>
          <Flex align="center" justify="flex-end" direction="row" mt={18}>
            <Button variant="outline" onClick={() => setIsRemoveLanguageModalOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={isDeletingLanguage || isRemoveLoading}
              onClick={handleRemoveLanguage}
              variant="filled"
              bg={theme.colors.red[6]}
              ml={8}
            >
              Remove
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Grid>
  );
};
