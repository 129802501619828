import { create } from 'zustand';
import { sourceLanguage } from '../../utils/content/contentUtils';
import { FileExplorerStatus } from '../file-explorer/useFileExplorerStore';

export interface IQuote {
  id: string | undefined;
  isSource: boolean;
  language: { label: string; value: string };
  quoteText: string;
  status: FileExplorerStatus;
}

interface QuotesStore {
  quoteAuthor: string;
  quoteCategory: string | undefined;
  quotes: IQuote[];
  handleSetValues: (quotes: IQuote[]) => void;
  handleSetQuoteAuthor: (val: string) => void;
  handleSetQuoteCategory: (val: string) => void;
  resetValues: () => void;
  resetStore: () => void;
}

const initialState = {
  quoteCategory: undefined,
  quoteAuthor: '',
  quotes: [
    {
      id: undefined,
      isSource: true,
      language: sourceLanguage,
      quoteText: '',
      status: FileExplorerStatus.DRAFT,
    },
  ],
};

export const useQuotesStore = create<QuotesStore>((set) => ({
  ...initialState,
  handleSetValues: (quotes: IQuote[]) => set({ quotes }),
  handleSetQuoteAuthor: (val: string) => set({ quoteAuthor: val }),
  handleSetQuoteCategory: (val: string) => set({ quoteCategory: val }),
  resetValues: () => set({ quotes: { ...initialState.quotes } }),
  resetStore: () => set({ ...initialState }),
}));
