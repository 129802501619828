import { create } from 'zustand';
import { sourceLanguage } from '../../utils/content/contentUtils';

export enum FileExplorerStatus {
  ALL = 'all',
  ACTIVE = 'active',
  DRAFT = 'draft',
  INACTIVE = 'inactive',
}

export enum FileExplorerViewType {
  GRID = 'grid',
  LIST = 'list',
}

export const fileExplorerStatusArr = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Draft', value: 'draft' },
  { label: 'Inactive', value: 'inactive' },
];

interface FileExplorerStore {
  fileExplorerLanguage: { label: string; value: string };
  fileExplorerSearchText: string;
  fileExplorerStatus: FileExplorerStatus;
  fileExplorerViewType: FileExplorerViewType;
  fileExplorerSelectedCategoryId: string | undefined;
  setFileExplorerLanguage: (lang: { label: string; value: string }) => void;
  setFileExplorerSearchText: (val: string) => void;
  setFileExplorerStatus: (val: FileExplorerStatus) => void;
  setFileExplorerViewType: (val: FileExplorerViewType) => void;
  setFileExplorerSelectedCategoryId: (val: string) => void;
  resetStore: () => void;
}

const initialState = {
  fileExplorerLanguage: sourceLanguage,
  fileExplorerSearchText: '',
  fileExplorerStatus: FileExplorerStatus.ALL,
  fileExplorerViewType: FileExplorerViewType.GRID,
  fileExplorerSelectedCategoryId: undefined,
};

export const useFileExplorerStore = create<FileExplorerStore>((set) => ({
  ...initialState,
  setFileExplorerLanguage: (lang: { label: string; value: string }) => set({ fileExplorerLanguage: lang }),
  setFileExplorerSearchText: (val: string) => set({ fileExplorerSearchText: val }),
  setFileExplorerStatus: (val: FileExplorerStatus) => set({ fileExplorerStatus: val }),
  setFileExplorerViewType: (val: FileExplorerViewType) => set({ fileExplorerViewType: val }),
  setFileExplorerSelectedCategoryId: (val: string) => set({ fileExplorerSelectedCategoryId: val }),
  resetStore: () => set({ ...initialState }),
}));
