import * as React from 'react';
import { useGetCategories } from '../queries/content/useGetCategories';
import { Select } from '@mantine/core';
import { Coding } from '@medplum/fhirtypes';

interface CategorySelectionProps {
  value: string;
  errorText: string;
  disabled: boolean;
  selectedLanguage: { label: string; value: string };
  onChange: (value: string | null) => void;
}

export const CategorySelection: React.FC<CategorySelectionProps> = ({
  value,
  disabled,
  errorText,
  onChange,
  selectedLanguage,
}) => {
  const { data: categoriesData } = useGetCategories('category', { retry: false, refetchOnWindowFocus: false });

  return (
    <Select
      disabled={disabled}
      size="sm"
      value={value}
      onChange={onChange}
      placeholder="Pick a category*"
      error={!!errorText}
      ml={8}
      data={
        categoriesData?.map((f: any) => {
          const translatedTitle =
            f?.code?.coding?.find((t: Coding) => t?.code === selectedLanguage.value)?.display ?? '';
          return { value: f?.id ?? '', label: translatedTitle };
        }) ?? []
      }
    />
  );
};
